import Vue from "vue";
import Router from "vue-router";
import store from "../store";

import Login from "../views/Login";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SideBar from "../components/Sidebar";

const UsersManager = () =>
  import(/* webpackChunkName: "admin-bundle" */ "../views/UsersManager.vue");
const GroupsManager = () =>
  import(/* webpackChunkName: "admin-bundle" */ "../views/GroupsManager.vue");
const ApplicationsManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "../views/ApplicationsManager.vue"
  );
const NotificatorManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "../views/NotificationsManager.vue"
  );
const Logs = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/Logs.vue");

const Dashboard = () =>
  import(/* webpackChunkName: "user-bundle" */ "../views/Dashboard.vue");
const Recovery = () =>
  import(/* webpackChunkName: "user-bundle" */ "../views/Recovery.vue");
const Registry = () =>
  import(/* webpackChunkName: "user-bundle" */ "../views/Registry.vue");
const PriceList = () =>
  import(/* webpackChunkName: "user-bundle" */ "../views/PriceList.vue");
const ProductsManager = () =>
  import(/* webpackChunkName: "user-bundle" */ "../views/ProductsManager.vue");
const InvoicesBuilder = () =>
  import(/* webpackChunkName: "user-bundle" */ "../views/InvoicesBuilder.vue");
const InvoicesReports = () =>
  import(/* webpackChunkName: "user-bundle" */ "../views/InvoicesReports.vue");
const PracticesManager = () =>
  import(/* webpackChunkName: "user-bundle" */ "../views/PracticesManager.vue");
const QuotesManager = () =>
  import(/* webpackChunkName: "user-bundle" */ "../views/QuotesManager.vue");
const Reset = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/Reset.vue");
const Profile = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/Profile.vue");
const FileManager = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/FileManager.vue");

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      components: {
        main: Login,
      },
    },
    {
      path: "/recovery",
      name: "recovery",
      components: {
        main: Recovery,
      },
    },
    {
      path: "/reset",
      name: "reset",
      components: {
        main: Reset,
      },
    },

    {
      path: "/",
      name: "dashboard",
      components: {
        header: Header,
        sideBar: SideBar,
        main: Dashboard,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/registry",
      name: "registry",
      components: {
        header: Header,
        sideBar: SideBar,
        main: Registry,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/pricelist",
      name: "pricelist",
      components: {
        header: Header,
        sideBar: SideBar,
        main: PriceList,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/products",
      name: "products",
      components: {
        header: Header,
        sideBar: SideBar,
        main: ProductsManager,
        footer: Footer,
      },

      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/practices",
      name: "practices",
      components: {
        header: Header,
        sideBar: SideBar,
        main: PracticesManager,
        footer: Footer,
      },

      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/quotes",
      name: "quotes",
      components: {
        header: Header,
        sideBar: SideBar,
        main: QuotesManager,
        footer: Footer,
      },

      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/invoices-builder",
      name: "invoices-builder",
      components: {
        header: Header,
        sideBar: SideBar,
        main: InvoicesBuilder,
        footer: Footer,
      },

      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/invoices-reports",
      name: "invoices-reports",
      components: {
        header: Header,
        sideBar: SideBar,
        main: InvoicesReports,
        footer: Footer,
      },

      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/permissions-users",
      name: "users",
      components: {
        header: Header,
        sideBar: SideBar,
        main: UsersManager,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/permissions-groups",
      name: "groups",
      components: {
        header: Header,
        sideBar: SideBar,
        main: GroupsManager,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/permissions-applications",
      name: "applications",
      components: {
        header: Header,
        sideBar: SideBar,
        main: ApplicationsManager,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true,
      },
    },

    {
      path: "/notifications",
      name: "notifications",
      components: {
        header: Header,
        sideBar: SideBar,
        main: NotificatorManager,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/profile",
      name: "profile",
      components: {
        header: Header,
        sideBar: SideBar,
        main: Profile,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/filemanager",
      name: "filemanager",
      components: {
        header: Header,
        sideBar: SideBar,
        main: FileManager,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/logs",
      name: "logs",
      components: {
        header: Header,
        sideBar: SideBar,
        main: Logs,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.meta.requiresAdmin && !store.state.isAdmin()) {
      next("/login");
      return;
    }

    if (to.meta.requiresSuperAdmin && !store.state.isSuperAdmin()) {
      next("/login");
      return;
    }

    if (store.state.user.id > 0) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
