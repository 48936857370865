import "./plugins";
import "./components/design";

import Vue from "vue";
import Vuetify from "vuetify";
import interceptors from "./services/interceptors";
import store from "./store";
import router from "./router";
import i18n from "./plugins/i18n";
import App from "./App.vue";

interceptors.setupInterceptors();

Vue.config.productionTip = false;

const vuetify = new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },

  theme: {
    themes: {
      light: {},
      dark: {},
    },
  },
});

new Vue({
  i18n,
  store,
  router,
  vuetify: vuetify,
  render: (h) => h(App),
  onIdle() {
    if (store.state.user.id && store.state.user.idlekick != 0) {
      // We are logged
    }
  },
}).$mount("#app");
