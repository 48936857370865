const rightsServices = (function(){  
    return {
     
      isAdmin : function (rights) {
        if (!rights || !Array.isArray(rights)) return false;
        if (rights.includes('a') || rights.includes('sa')) return true;
        return false;
      },

      isSuperAdmin : function (rights) {
        if (!rights || !Array.isArray(rights)) return false;
        if (rights.includes('sa')) return true;
        return false;
      },

      hasExactRight : function (rights, right) {
        if (!rights || !Array.isArray(rights)) return false;
        if (rights.includes(right)) return true;
        return false;
      },

      hasRight : function (rights, right) {
        if (!rights || !Array.isArray(rights)) return false;
        if (rightsServices.isSuperAdmin(rights) || rightsServices.isAdmin(rights)) return true;
        if (rights.includes(right)) return true;
        return false;
      },

    }
   })();
   
   export default rightsServices;