import Vue from "vue";
import VueI18n from "vue-i18n";

import it from "../locales/it.json";

let messages = {
  it: it,
  en: it,
};

Vue.use(VueI18n);

export default new VueI18n({
  locale: "it",
  fallbackLocale: "it",
  messages: messages,
});
